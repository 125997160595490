import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import first from 'lodash/first';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components/macro';
import { prop, theme } from 'styled-tools';
import { up } from 'styled-breakpoints';
import { graphql, Link, StaticQuery } from 'gatsby';
import Button from 'components/Button';
import RichText from 'components/RichText';
import PrismicLink from 'components/PrismicLink';
import AppDownload from 'components/AppDownload';
import MailchimpForm from 'components/MailchimpForm';
import BaseContainer from 'react-bootstrap/Container';
import { isEmptyOrNull } from 'utils/functions';
import basicCtaShape from 'shapes/basicCtaShape';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { withPreview } from 'gatsby-source-prismic-graphql';
import appDownloadLinksDataShape from 'shapes/appDownloadLinksDataShape';
import AppDownloadContainer from 'components/AppDownload/AppDownloadContainer';
import MediaDownloadContainer from 'components/MediaDownload/MediaDownloadContainer';
import MediaDownload from 'components/MediaDownload';

const Container = styled(BaseContainer)`
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: 5rem;

  ${up('sm')} {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  ${AppDownloadContainer} {
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }

  ${MediaDownloadContainer} {
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }
`;

const CopyContainer = styled.div`
  > * {
    font-weight: ${theme('fontWeights.default.medium')};
  }
`;

const CtaLink = styled(PrismicLink)`
  margin-bottom: 1rem;
`;

const appDownloadLinksQuery = graphql`
  query BasicCtaAppDownloadLinksQuery {
    prismic {
      allSocial_medias {
        edges {
          node {
            ...AppDownloadLinksFragment
            ...MediaDownloadLinksFragment
          }
        }
      }
    }
  }
`;

const propTypes = {
  data: basicCtaShape.isRequired,
  appDownloadLinksData: appDownloadLinksDataShape,
  className: PropTypes.string,
};

function BasicCta({ data, appDownloadLinksData, className }) {
  const appDownloadLinks = get(
    appDownloadLinksData,
    'prismic.allSocial_medias.edges[0].node',
    {}
  );

  const {
    appStoreImage,
    appStoreLink,
    googlePlayLink,
    googlePlayImage,
    appButtons,
    youtubeLink,
    spotifyLink,
    applePodcastLink,
    youtubeImage,
    spotifyImage,
    applePodcastImage,
  } = appDownloadLinks;

  return (
    <Container className={className}>
      <Row className='align-items-center'>
        <Col sm={12} md={6}>
          {data.primary.cta_header && (
            <RichText render={data.primary.cta_header} />
          )}
          <CopyContainer>
            {data.primary.cta_copy && (
              <RichText render={data.primary.cta_copy} />
            )}
          </CopyContainer>
          {data.primary.signup_form && (
            <MailchimpForm
              emailCaptureText={
                !isEmptyOrNull(data.primary.email_capture_field)
                  ? first(data.primary.email_capture_field).text
                  : 'Your email address'
              }
              emailButtonText={
                !isEmptyOrNull(data.primary.email_capture_button_text)
                  ? first(data.primary.email_capture_button_text).text
                  : 'Subscribe'
              }
              signupForm={data.primary.signup_form}
            />
          )}
        </Col>
        <Col
          className='text-md-right text-left align-content-center'
          sm={12}
          md={6}
        >
          {data.primary.cta_link && data.primary.cta_link_text && (
            <CtaLink
              link={data.primary.cta_link}
              internalAs={Button}
              internalProps={{ forwardedAs: Link }}
              externalAs={Button}
              externalProps={{ target: '_blank' }}
              variant={'primary'}
            >
              {data.primary.cta_link_text}
            </CtaLink>
          )}
          {data.primary.app_button_downloads && (
            <>
              {isIOS && appStoreLink && appStoreImage && (
                <AppDownload
                  appLogo={appStoreImage}
                  downloadLink={appStoreLink}
                />
              )}
              {isAndroid && googlePlayLink && googlePlayImage && (
                <AppDownload
                  appLogo={googlePlayImage}
                  downloadLink={googlePlayLink}
                />
              )}
              {!isMobile && (
                <AppDownloadContainer>
                  {googlePlayLink && googlePlayImage && (
                    <AppDownload
                      appLogo={googlePlayImage}
                      downloadLink={googlePlayLink}
                    />
                  )}
                  {appStoreLink && appStoreImage && (
                    <AppDownload
                      appLogo={appStoreImage}
                      downloadLink={appStoreLink}
                    />
                  )}
                </AppDownloadContainer>
              )}
            </>
          )}
          {data.primary.media_button_downloads && (
            <MediaDownloadContainer>
              {youtubeLink && youtubeImage && (
                <MediaDownload
                  mediaLogo={youtubeImage}
                  mediaLink={youtubeLink}
                />
              )}
              {spotifyLink && spotifyImage && (
                <MediaDownload
                  mediaLogo={spotifyImage}
                  mediaLink={spotifyLink}
                />
              )}
              {applePodcastLink && applePodcastImage && (
                <MediaDownload
                  mediaLogo={applePodcastImage}
                  mediaLink={applePodcastLink}
                />
              )}
            </MediaDownloadContainer>
          )}
        </Col>
      </Row>
    </Container>
  );
}

BasicCta.propTypes = propTypes;

export default (props) => (
  <StaticQuery
    query={appDownloadLinksQuery}
    render={withPreview(
      (data) => (
        <BasicCta appDownloadLinksData={data} {...props} />
      ),
      appDownloadLinksQuery
    )}
  />
);
