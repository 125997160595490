import React from 'react';
import get from 'lodash/get';
import { Link } from 'gatsby';
import { ifProp, prop, theme } from 'styled-tools';
import { between, down, up } from 'styled-breakpoints';
import styled, { css } from 'styled-components/macro';
import { Col as BaseCol, Row as BaseRow } from 'react-bootstrap';
import BaseContainer from 'react-bootstrap/Container';
import twoBlockShape from 'shapes/twoBlockShape';
import RichText from 'components/RichText';
import PrismicLink from 'components/PrismicLink';
import Button from 'components/Button';
import { getImgAlt } from 'utils/functions';
import PropTypes from 'prop-types';

const propTypes = {
  data: twoBlockShape.isRequired,
  className: PropTypes.string,
};

const Block = styled.div`
  padding-bottom: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;

  ${up('xs')} {
    padding-bottom: 80%;
  }

  ${up('md')} {
    padding-bottom: 85%;
  }

    ${ifProp(
      ['img', { gradient: true }],
      css`
    ${up('sm')} {
      background-image: linear-gradient(183deg, rgba(0,0,0,0.38) 0%, #000000 92%), url("${prop(
        'img'
      )}");
    }
    `
    )}

    ${ifProp(
      ['img', { mobile: true }],
      css`
    ${down('sm')} {
      background-image: linear-gradient(183deg, rgba(0,0,0,0.38) 0%, #000000 92%), url("${prop(
        'img'
      )}");
    }
    `
    )}

  ${ifProp(
    'img',
    css`
    background-position-x: right;
    background-size: cover;
    background-image: url("${prop('img')}");
    background-repeat: no-repeat;
  `
  )}

  ${ifProp(
    'backgroundColor',
    css`
      background-color: ${prop('backgroundColor')};
    `
  )}
`;

const BlockInner = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  padding: 1rem;
  position: absolute;

  ${up('sm')} {
    padding: 2rem;
  }

  ${up('md')} {
    padding: 3rem;
  }
`;

const Col = styled(BaseCol)`
  &:first-child {
    padding: 0 0 0.46875rem;

    ${down('sm')} {
      margin-bottom: 0;
    }

    ${between('md', 'lg')} {
      padding: 0 0.46875rem 0 0.9375rem;
    }

    ${up('lg')} {
      padding: 0.5rem 1rem 0.5rem 0.5rem;
    }

    ${Block} {
      ${up('md')} {
        border-top-left-radius: 0.1rem;
        border-bottom-left-radius: 0.1rem;
      }
    }
  }

  &:last-child {
    padding: 0.46875rem 0 0;

    ${down('sm')} {
      margin-top: 0;
    }

    ${between('md', 'lg')} {
      padding: 0 0.9375rem 0 0.46875rem;
    }

    ${up('lg')} {
      padding: 0.5rem 1rem 0.5rem 0.5rem;
    }

    ${Block} {
      ${up('md')} {
        border-top-right-radius: 0.1rem;
        border-bottom-right-radius: 0.1rem;
      }
    }
  }
`;

const Row = styled(BaseRow)`
  ${down('xs')} {
    margin: 0;
  }
`;

const TwoBlockButton = styled(Button)`
  border-color: ${theme('colors.black')};
  color: ${theme('colors.black')};
`;

const Container = styled(BaseContainer)`
  margin-top: 2rem;
  margin-bottom: 2rem;

  && {
    ${down('xs')} {
      padding: 0;
    }
  }

  ${down('md')} {
    max-width: 100%;
  }

  ${up('md')} {
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
`;

function TwoBlock({ data, className }) {
  return (
    <Container className={className}>
      <Row>
        {data.fields.map((field, index) => (
          <Col key={index} sm={12} md={6}>
            <Block
              alt={field.image && getImgAlt(get(field, 'image'))}
              img={get(field, 'image.url')}
              gradient={field.gradient}
              backgroundColor={get(field, 'background_color')}
              mobile={field.mobile}
            >
              <BlockInner>
                {field.sub_title && (
                  <div>
                    <RichText render={field.sub_title} />
                  </div>
                )}
                {field.title && (
                  <div>
                    <RichText render={field.title} />
                  </div>
                )}
                {field.description && <RichText render={field.description} />}
                {field.cta_text && field.cta_link && (
                  <PrismicLink
                    link={field.cta_link}
                    internalAs={
                      field.ctaLinkStyle === 'Button' ? TwoBlockButton : Link
                    }
                    internalProps={{ forwardedAs: Link }}
                    externalAs={
                      field.ctaLinkStyle === 'Button' ? TwoBlockButton : Link
                    }
                    externalProps={{ target: '_blank' }}
                    variant={'primary'}
                  >
                    {field.cta_text}
                  </PrismicLink>
                )}
              </BlockInner>
            </Block>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

TwoBlock.propTypes = propTypes;

export default TwoBlock;
