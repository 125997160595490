import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components/macro';

const StyledCardHeader = styled.h2`
  &&& {
    color: ${prop('theme.colors.primary')};
    font-family: ${prop('theme.fonts.default')};
    margin: 0;
    padding-bottom: 0.625rem;
  }
`;

const propTypes = {
  description: PropTypes.string,
  isBoxHeader: PropTypes.string,
  title: PropTypes.string,
};

function TableTitle({ description = '', isBoxHeader = '', title = null }) {
  if (!title) {
    return null;
  }

  return (
    <>
      {title && !isBoxHeader && <StyledCardHeader>{title}</StyledCardHeader>}
      {description && !isBoxHeader && <p>{description}</p>}
    </>
  );
}

TableTitle.propTypes = propTypes;

export default TableTitle;
