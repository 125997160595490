import React from 'react';
import prismicLinkShape from 'shapes/prismicLinkShape';
import prismicImageShape from 'shapes/prismicImageShape';
import MediaDownload from 'components/MediaDownload';
import MediaDownloadContainer from 'components/MediaDownload/MediaDownloadContainer';

const propTypes = {
  youtubeImage: prismicImageShape,
  youtubeLink: prismicLinkShape,
  spotifyImage: prismicImageShape,
  spotifyLink: prismicLinkShape,
  applePodcastImage: prismicImageShape,
  applePodcastLink: prismicLinkShape,
};

function MediaDownloads({
  youtubeImage,
  youtubeLink,
  spotifyImage,
  spotifyLink,
  applePodcastImage,
  applePodcastLink,
}) {
  return (
    <MediaDownloadContainer>
      {youtubeLink && youtubeImage && (
        <MediaDownload mediaLogo={youtubeImage} mediaLink={youtubeLink} />
      )}
      {spotifyLink && spotifyImage && (
        <MediaDownload mediaLogo={spotifyImage} mediaLink={spotifyLink} />
      )}
      {applePodcastLink && applePodcastImage && (
        <MediaDownload
          mediaLogo={applePodcastImage}
          mediaLink={applePodcastLink}
        />
      )}
    </MediaDownloadContainer>
  );
}

MediaDownloads.propTypes = propTypes;

export default MediaDownloads;
