import React from 'react';
import map from 'lodash/map';
import first from 'lodash/first';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components/macro';
import StyledTd from './styled/StyledTd';
import HeaderContent from './HeaderContent';
import RichText from 'components/RichText';

export const HeaderWrapper = styled.span`
  display: flex;
  text-align: left;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    display: none;
  }
`;

const propTypes = {
  borderColor: PropTypes.string,
  columns: PropTypes.object,
  rows: PropTypes.array,
};

const defaultProps = {
  columns: {},
  rows: [],
};

function TableRows({ borderColor, columns, rows }) {
  return map(rows, function (row, index) {
    let values = map(columns, function (column, columnKey) {
      let matchColumnKey = columnKey + 'Data';
      let columnName =
        column && first(column).text ? first(column).text : column;
      let columnValue = first(row[matchColumnKey]);
      let cellArrayRich = [];
      let cellArrayRegular = [];

      if (columnName) {
        if (typeof columnValue === 'object') {
          cellArrayRich.push(columnValue);
        } else {
          cellArrayRegular.push(row[matchColumnKey]);
        }

        return (
          <StyledTd
            borderColor={borderColor}
            withRichText={cellArrayRich.length > 0 ? 'true' : 'false'}
            key={columnKey}
          >
            <HeaderWrapper
              withRichText={cellArrayRich.length > 0 ? 'true' : 'false'}
            >
              <HeaderContent column={column} />
            </HeaderWrapper>
            {cellArrayRich.length > 0 ? (
              <RichText render={cellArrayRich} />
            ) : (
              <span>{cellArrayRegular}</span>
            )}
          </StyledTd>
        );
      }
    });

    return <tr key={`row-${index}`}>{values}</tr>;
  });
}

TableRows.propTypes = propTypes;
TableRows.defaultProps = defaultProps;

export default TableRows;
