import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import Button from 'components/Button';
import { ifProp } from 'styled-tools';
import styled, { css } from 'styled-components/macro';

const ButtonContainer = styled.span`
  ${Button} {
    line-height: normal;
    padding-top: 0;
    vertical-align: unset;
    font-size: 0.9rem;
    padding-left: 1rem;
    padding-right: 0;
  }

  ${ifProp(
    { type: 'less' },
    css`
      ${Button} {
        padding-left: 0;
      }
    `
  )}
`;

const propTypes = {
  children: PropTypes.node.isRequired,
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string,
};

function ReviewDescription({
  lines = 3,
  less = 'Show less',
  more = 'Read more',
  children,
}) {
  const [expanded, setExpanded] = useState(false);
  const [truncated, setTruncated] = useState(false);

  const handleTruncate = (truncate) => {
    if (truncated !== truncate) {
      setTruncated(truncated);
    }
  };

  const toggleLines = (event) => {
    event.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <div>
      <Truncate
        lines={!expanded && lines}
        ellipsis={
          <ButtonContainer>
            ...
            <Button onClick={toggleLines} variant='link'>
              {more}
            </Button>
          </ButtonContainer>
        }
        onTruncate={handleTruncate}
      >
        {children}
      </Truncate>
      {!truncated && expanded && (
        <ButtonContainer type='less'>
          <Button onClick={toggleLines} variant='link'>
            {less}
          </Button>
        </ButtonContainer>
      )}
    </div>
  );
}

ReviewDescription.propTypes = propTypes;

export default ReviewDescription;
