import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import { Col, Row } from 'react-bootstrap';

const StyledRow = styled(Row)`
  background-color: ${prop('theme.colors.white')};
  border: 0.0625rem solid ${prop('theme.colors.greyLighter')};
  border-bottom: none;
  display: flex;
  min-height: 7rem;
  position: relative;
`;

const DiagonalContainer = styled.div`
  height: 7rem;
  max-width: 7rem;
  overflow: hidden;
  position: relative;
  width: 7rem;

  &:before {
    border-top: 0.0625rem solid ${prop('theme.colors.greyLighter')};
    content: '';
    left: -50%;
    right: 0;
    position: absolute;
    transform: rotate(-45deg);
    transform-origin: 100% 0;
  }
`;

const DiagonalText = styled.p`
  &&& {
    color: ${prop('theme.colors.redPrimary')};
    font-family: ${prop('theme.fonts.default')};
    font-weight: ${prop('theme.fontWeights.default.bold')};
    font-style: normal;
    font-stretch: normal;
    font-size: 0.875rem;
    line-height: normal;
    max-width: 6.75rem;
    margin-left: -0.375rem;
    padding: 0.375rem 0 0 0.375rem;
    transform: rotate(-45deg);

    @media (max-width: ${prop('theme.breakpoints.sm')}) {
      max-width: 5rem;
      margin-left: 0.125rem;
      margin-top: 0.75rem;
    }
  }
`;

const TextCol = styled(Col)`
  display: flex;
  align-items: center;
  font-family: ${prop('theme.fonts.default')};
  font-size: 1.125rem;
  font-weight: ${prop('theme.fontWeights.default.medium')};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.005rem;
  color: ${prop('theme.colors.black')};

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    font-size: 0.875rem;
    padding: 0 0 0 1.875rem;
    word-break: break-word;
  }
`;

const propTypes = {
  blockId: PropTypes.string,
  banner: PropTypes.string,
  title: PropTypes.string,
};

const defaultProps = {
  blockId: '',
  banner: '',
  title: null,
};

function BoxTableTitle({ banner, title }) {
  if (!title) {
    return null;
  }

  return (
    <StyledRow noGutters className='anchorOffset'>
      <Col xs={4} md={2}>
        <DiagonalContainer>
          <DiagonalText>{banner}</DiagonalText>
        </DiagonalContainer>
      </Col>
      <TextCol xs={8} md={10}>
        {title}
      </TextCol>
    </StyledRow>
  );
}

BoxTableTitle.propTypes = propTypes;
BoxTableTitle.defaultProps = defaultProps;

export default BoxTableTitle;
