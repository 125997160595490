import React from 'react';
import PropTypes from 'prop-types';
import Slice from './Slice';
import styled from 'styled-components/macro';
import sliceDataShape from 'shapes/sliceDataShape';
import BlockStyle from 'components/Slices/BlockStyle';

const TYPE_BLOCK_STYLE = 'block_style';
const TYPE_BLOCK_STYLE_END = 'block_style_end';

const SlicesContainer = styled.div`
  // Remove bottom margin of the last slice on the page
  // if it's a slice type that should be flush with the footer.
  > div {
    &:last-child {
      &.slice-basic_cta,
      &.slice-cta_banner,
      &.slice-featured_on {
        margin-bottom: 0;
      }
    }
  }
`;

/**
 * Process the array of slices to look for any block_style slices, and if any are found,
 * nest the appropriate slices following the block_style as children of the block style.
 *
 * If slice type is block_style, then we do a lookahead on the upcoming slices
 * to determine how many slices should be nested within this block style.
 *
 * If there is a block_style_end in the future, all slices between the block_style and block_style_end
 * slice types will be included as children nested under this block_style.
 *
 * If there is no block_style_end in the future, then we assume that the block_style
 * applies only to the block immediately following it.
 */
function processBlockStyleSlices(slices) {
  const processedSlices = [];

  for (let i = 0; i < slices.length; i++) {
    const slice = slices[i];

    if (slice.type === TYPE_BLOCK_STYLE) {
      // Get subset of all slices following this block style slice.
      const upcomingSlices = slices.slice(i + 1);

      // Find the index in upcomingSlices array for the last slice included in this block style.
      const endIndex = getLastSliceIndexForBlockStyle(upcomingSlices);

      // If the last slice is a block_style or block_style_end, we want to exclude it from our child slices.
      const lastBlockSliceIndex = isBlockStyleBoundarySliceType(
        upcomingSlices[endIndex]
      )
        ? endIndex - 1
        : endIndex;

      // If last block slice index is < 0, then this block style applies to no slices and is skipped.
      if (lastBlockSliceIndex >= 0) {
        // Since "end" argument to slice is not included in the result, we add + 1 here.
        const childSlices = upcomingSlices.slice(0, lastBlockSliceIndex + 1);
        processedSlices.push({ ...slice, childSlices });

        i += endIndex + 1;
      }
    } else if (slice.type !== TYPE_BLOCK_STYLE_END) {
      // As long as the slice type isn't a rogue block_style_end type, we add this slice
      // to be included as the top level output.
      processedSlices.push(slice);
    }
  }
  return processedSlices;
}

function getLastSliceIndexForBlockStyle(upcomingSlices) {
  let endIndex = false;

  for (let k = 0; k < upcomingSlices.length; k++) {
    const upcomingSlice = upcomingSlices[k];

    if (upcomingSlice.type === TYPE_BLOCK_STYLE) {
      // If we encounter another block_style before any block style end,
      // we default to apply to only the slice following the block style.
      endIndex = 0;
      break;
    } else if (upcomingSlice.type === TYPE_BLOCK_STYLE_END) {
      endIndex = k;
      break;
    }
  }

  if (false === endIndex) {
    // If endIndex is false, that means that there was no block style end or followup block style,
    // so it will default to apply only to the next block.
    endIndex = 0;
  }

  return endIndex;
}

function isBlockStyleBoundarySliceType(slice) {
  return (
    slice &&
    (slice.type === TYPE_BLOCK_STYLE || slice.type === TYPE_BLOCK_STYLE_END)
  );
}

const propTypes = {
  slices: PropTypes.arrayOf(sliceDataShape),
};

function Slices({ slices, ...props }) {
  if (!slices || slices.length === 0) {
    return null;
  }

  const processedSlices = processBlockStyleSlices(slices);

  return (
    <SlicesContainer>
      {processedSlices.map((slice, index) => {
        if (slice.type === TYPE_BLOCK_STYLE) {
          return (
            <BlockStyle data={slice} key={index} {...props}>
              {slice.childSlices.map((childSlice, childIndex) => (
                <Slice
                  data={childSlice}
                  blockStyle={slice}
                  key={`${index}_${childIndex}`}
                  {...props}
                />
              ))}
            </BlockStyle>
          );
        }

        return <Slice data={slice} key={index} {...props} />;
      })}
    </SlicesContainer>
  );
}

Slices.propTypes = propTypes;

export default Slices;
