import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import PrismicLink from 'components/PrismicLink';
import AppDownloads from './AppDownloads';
import MediaDownloads from './MediaDownloads';
import prismicImageShape from 'shapes/prismicImageShape';
import prismicLinkShape from 'shapes/prismicLinkShape';

const propTypes = {
  buttonText: PropTypes.string,
  buttonLink: prismicLinkShape,
  buttonComponent: PropTypes.object,
  appStoreLink: prismicLinkShape,
  appStoreImage: prismicImageShape,
  googlePlayLink: prismicLinkShape,
  googlePlayImage: prismicImageShape,
  appButtons: PropTypes.bool,
  mediaButtons: PropTypes.bool,
  youtubeLink: prismicLinkShape,
  youtubeImage: prismicImageShape,
  spotifyLink: prismicLinkShape,
  spotifyImage: prismicImageShape,
  applePodcastLink: prismicLinkShape,
  applePodcastImage: prismicImageShape,
};

function CtaBannerButtons({
  buttonText,
  buttonLink,
  buttonComponent,
  appStoreLink,
  appStoreImage,
  googlePlayLink,
  googlePlayImage,
  appButtons,
  mediaButtons,
  youtubeLink,
  youtubeImage,
  spotifyLink,
  spotifyImage,
  applePodcastLink,
  applePodcastImage,
}) {

  return (
    <>
      {buttonText && buttonLink && (
        <PrismicLink
          link={buttonLink}
          internalAs={buttonComponent}
          internalProps={{ forwardedAs: Link }}
          externalAs={buttonComponent}
          externalProps={{
            target: '_blank',
            href: buttonLink.url,
          }}
        >
          {buttonText}
        </PrismicLink>
      )}
      {appButtons && (
        <AppDownloads
          appStoreLink={appStoreLink}
          appStoreImage={appStoreImage}
          googlePlayLink={googlePlayLink}
          googlePlayImage={googlePlayImage}
        />
      )}
      {mediaButtons && (
        <MediaDownloads
          youtubeLink={youtubeLink}
          youtubeImage={youtubeImage}
          spotifyLink={spotifyLink}
          spotifyImage={spotifyImage}
          applePodcastLink={applePodcastLink}
          applePodcastImage={applePodcastImage}
        />
      )}
    </>
  );
}

CtaBannerButtons.propTypes = propTypes;

export default CtaBannerButtons;
