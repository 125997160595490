import PropTypes from 'prop-types';
import reviewShape from './reviewShape';

const { shape, string, arrayOf } = PropTypes;

export default shape({
  type: string.isRequired,
  label: string,
  primary: arrayOf(
    shape({
      status: string,
      content: reviewShape,
    })
  ),
});
