import PropTypes from 'prop-types';
import prismicRichTextShape from 'shapes/prismicRichTextShape';

const { shape, string, arrayOf } = PropTypes;

export default shape({
  type: string.isRequired,
  label: string,
  primary: shape({
    multi_block_disclaimer: prismicRichTextShape,
    multi_block_title: prismicRichTextShape,
  }),
  fields: arrayOf(
    shape({
      multi_block_copy: prismicRichTextShape,
      multi_block_header: prismicRichTextShape,
      multi_block_border_color: string
    })
  ),
});
