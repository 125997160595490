import React from 'react';
import get from 'lodash/get';
import BaseRow from 'react-bootstrap/Row';
import BaseCol from 'react-bootstrap/Col';
import BaseContainer from 'react-bootstrap/Container';
import styled from 'styled-components/macro';
import RichText from 'components/RichText';
import teamBioShape from 'shapes/teamBioShape';
import { down, up } from 'styled-breakpoints';
import { getImgAlt } from 'utils/functions';
import { ifProp, prop, theme } from 'styled-tools';
import { css } from 'styled-components/macro';
import PropTypes from 'prop-types';

const propTypes = {
  data: teamBioShape.isRequired,
  className: PropTypes.string,
};

const Row = styled(BaseRow)`
  margin-top: 1rem;
`;

const Col = styled(BaseCol)`
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const TeamBioContainer = styled.div`
  background-color: ${theme('colors.black')};
`;

const Container = styled(BaseContainer)`
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-top: 1rem;

  ${up('md')} {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
`;

const TitleContainer = styled.div`
  color: ${theme('colors.white')};
  margin-top: 2rem;
`;

const ImageContainer = styled.div`
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-top-right-radius: 0.1rem;
  border-top-left-radius: 0.1rem;

  ${ifProp(
    'img',
    css`
    background-image: url("${prop('img')}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  `
  )}

  img {
    width: 50%;
    height: inherit;
  }
`;

const BodyContainer = styled.div`
  background-color: white;
  padding: 1rem;
  border-bottom-right-radius: 0.1rem;
  border-bottom-left-radius: 0.1rem;
`;

const TitleWrapper = styled.div`
  align-self: flex-start;
  padding: 1rem;
`;

function TeamBio({ data, className }) {
  return (
    <TeamBioContainer className={className}>
      <Container>
        {data.primary && (
          <TitleContainer>
            <RichText render={get(data.primary, 'teamBioTitle')} />
          </TitleContainer>
        )}
        <Row>
          {data.fields.map((teamBio, index) => {
            const leadershipTeam = teamBio && teamBio.leadershipTeam;

            return (
              <Col md={4} key={`team-bio-${index}`}>
                {leadershipTeam && leadershipTeam.image && (
                  <ImageContainer
                    img={get(leadershipTeam, 'image.url')}
                    alt={getImgAlt(leadershipTeam.image)}
                  >
                    <TitleWrapper>
                      {leadershipTeam && (
                        <RichText render={leadershipTeam.name} />
                      )}
                      {leadershipTeam && (
                        <RichText render={leadershipTeam.title} />
                      )}
                    </TitleWrapper>
                  </ImageContainer>
                )}
                <BodyContainer>
                  {leadershipTeam && (
                    <RichText render={leadershipTeam.bioBlurb} />
                  )}
                </BodyContainer>
              </Col>
            );
          })}
        </Row>
      </Container>
    </TeamBioContainer>
  );
}

TeamBio.propTypes = propTypes;

export default TeamBio;
