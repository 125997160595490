import React from 'react';
import TeamBio from './TeamBio';
import BasicCta from './BasicCta';
import Reviews from './Reviews';
import TwoBlock from './TwoBlock';
import CtaBanner from './CtaBanner';
import TextBlock from './TextBlock';
import TableBlock from './TableBlock';
import LearnBlock from './LearnBlock';
import FeaturedOn from './FeaturedOn';
import BlogListing from './BlogListing';
import CenteredFeature from './CenteredFeature';
import MultiBlock from 'components/Slices/MultiBlock';
import FeaturedImageLeftRight from './FeaturedImageLeftRight';
import Differentiator from 'components/Slices/Differentiator';
import Carousel from 'components/Slices/Carousel';
import sliceDataShape from 'shapes/sliceDataShape';

const sliceComponents = {
  text_block: TextBlock,
  dough_table: TableBlock,
  blog_listing: BlogListing,
  basic_cta: BasicCta,
  two_panel: FeaturedImageLeftRight,
  centered_feature: CenteredFeature,
  reviews: Reviews,
  two_block_slice: TwoBlock,
  cta_banner: CtaBanner,
  team_bio: TeamBio,
  multi_block: MultiBlock,
  differentiator: Differentiator,
  learn_block: LearnBlock,
  carousel_and_commentary: Carousel,
  carousel: Carousel,
  featured_on: FeaturedOn,
};

const propTypes = {
  data: sliceDataShape.isRequired,
};

function Slice({ data, ...props }) {
  const SliceComponent = sliceComponents[data.type];

  if (!SliceComponent) {
    return null;
  }

  return (
    <SliceComponent data={data} className={`slice-${data.type}`} {...props} />
  );
}

Slice.propTypes = propTypes;

export default Slice;
