import PropTypes from 'prop-types';
import richText from 'shapes/prismicRichTextShape';

const { shape, string, arrayOf } = PropTypes;

export default shape({
  type: string.isRequired,
  label: string,
  primary: shape({
    table_title: richText,
    table_description: richText,
    table_border: string,
    disclaimer: richText,
    col1: richText,
    col2: richText,
    col3: richText,
    col4: richText,
    col5: richText,
    col6: richText,
    col7: richText,
    col8: richText,
    col9: richText,
    col10: richText,
  }),
  fields: arrayOf(
    shape({
      col1Data: richText,
      col2Data: richText,
      col3Data: richText,
      col4Data: richText,
      col5Data: richText,
      col6Data: richText,
      col7Data: richText,
      col8Data: richText,
      col9Data: richText,
      col10Data: richText,
    })
  ),
});
