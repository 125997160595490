import PropTypes from 'prop-types';
import basicCtaShape from 'shapes/basicCtaShape';
import blockStyleShape from 'shapes/blockStyleShape';
import carouselShape from 'shapes/carouselShape';
import centeredFeatureShape from 'shapes/centeredFeatureShape';
import ctaBannerShape from 'shapes/ctaBannerShape';
import differentiatorShape from 'shapes/differentiatorShape';
import featuredImageLeftRightShape from 'shapes/featuredImageLeftRightShape';
import learnBlockShape from 'shapes/learnBlockShape';
import multiBlockShape from 'shapes/multiBlockShape';
import reviewsShape from 'shapes/reviewsShape';
import tableBlockShape from 'shapes/tableBlockShape';
import teamBioShape from 'shapes/teamBioShape';
import textBlockShape from 'shapes/textBlockShape';
import twoBlockShape from 'shapes/twoBlockShape';

export default PropTypes.oneOfType([
  basicCtaShape,
  blockStyleShape,
  carouselShape,
  centeredFeatureShape,
  ctaBannerShape,
  differentiatorShape,
  featuredImageLeftRightShape,
  learnBlockShape,
  multiBlockShape,
  reviewsShape,
  tableBlockShape,
  teamBioShape,
  textBlockShape,
  twoBlockShape,
]);
