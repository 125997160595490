import React from 'react';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components/macro';
import StyledTh from './styled/StyledTh';
import HeaderContent from './HeaderContent';

export const StyledThead = styled.thead`
  background-color: transparent;
  display: none;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    display: table-header-group;
  }
`;

const propTypes = {
  columns: PropTypes.object,
};

function TableHeader({ columns = {} }) {
  let mappedColumns = map(columns, function (column, index) {
    if (column !== null) {
      return (
        <StyledTh key={index}>
          <HeaderContent column={column} />
        </StyledTh>
      );
    }
  });

  return (
    <StyledThead>
      <tr>{mappedColumns}</tr>
    </StyledThead>
  );
}

TableHeader.propTypes = propTypes;

export default TableHeader;
