import PropTypes from 'prop-types';
import prismicRichTextShape from 'shapes/prismicRichTextShape';
import prismicLinkShape from 'shapes/prismicLinkShape';
import prismicImageShape from 'shapes/prismicImageShape';

const { shape, string, arrayOf, bool } = PropTypes;

export default shape({
  type: string.isRequired,
  label: string,
  fields: arrayOf(
    shape({
      title: prismicRichTextShape,
      sub_title: prismicRichTextShape,
      description: prismicRichTextShape,
      background_color: string,
      cta_link: prismicLinkShape,
      ctaLinkStyle: string,
      cta_text: string,
      image: prismicImageShape,
      gradient: bool,
      mobile: bool,
    })
  ),
});
