import PropTypes from 'prop-types';
import prismicRichTextShape from 'shapes/prismicRichTextShape';
import prismicLinkShape from './prismicLinkShape';

const { shape, string, arrayOf } = PropTypes;

export default shape({
  reviews: arrayOf(
    shape({
      title: prismicRichTextShape,
      description: prismicRichTextShape,
      personName: string,
      reviewDate: string,
      rating: string,
      source: prismicRichTextShape,
      sourceLink: prismicLinkShape,
    })
  ),
});
