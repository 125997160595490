import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import PrismicLink from 'components/PrismicLink';
import prismicImageShape from 'shapes/prismicImageShape';
import prismicLinkShape from 'shapes/prismicLinkShape';
import { getImgAlt } from 'utils/functions';
import styled from 'styled-components/macro';
import { up } from 'styled-breakpoints';

const MediaLink = styled(PrismicLink)`
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
  margin-right: 1.25rem;
  max-height: 2.25rem;

  ${up('xl')} {
    margin-bottom: 0;
    margin-right: 1.25rem;
  }

  &:last-child {
    margin-bottom: 0;

    ${up('xl')} {
      margin-right: 0;
    }
  }

  img {
    max-height: inherit;
    width: auto;
  }
`;

const propTypes = {
  mediaLogo: prismicImageShape.isRequired,
  mediaLink: prismicLinkShape.isRequired,
};

function MediaDownload({ mediaLogo, mediaLink }) {
  return (
    <MediaLink
      link={mediaLink}
      internalAs={Link}
      internalProps={{ as: Link }}
      externalAs='a'
      externalProps={{
        target: '_blank',
      }}
    >
      {mediaLogo && <img alt={getImgAlt(mediaLogo)} src={mediaLogo.url} />}
    </MediaLink>
  );
}

MediaDownload.propTypes = propTypes;

export default MediaDownload;
