import React from 'react';
import first from 'lodash/first';
import { down, up } from 'styled-breakpoints';
import { ifProp } from 'styled-tools';
import styled, { css } from 'styled-components/macro';
import { Col, Row as BaseRow } from 'react-bootstrap';
import Button from 'components/Button';
import RichText from 'components/RichText';
import Container from 'react-bootstrap/Container';
import { LEFT_LAYOUT, RIGHT_LAYOUT } from 'utils/constants';
import featuredImageLeftRightShape from 'shapes/featuredImageLeftRightShape';
import { Link } from 'gatsby';
import { getImgAlt } from 'utils/functions';
import PrismicLink from '../../PrismicLink';

const Row = styled(BaseRow)`
  margin: 3rem 0;

  ${down('sm')} {
    margin: 2rem 0;
  }

  ${ifProp(
    { alignment: LEFT_LAYOUT },
    css`
      // Always order the image above the text when in mobile no matter the alignment.
      flex-direction: column-reverse;

      ${up('md')} {
        flex-direction: row;
      }

      .col-md-6 {
        ${down('sm')} {
          padding: 0;
        }

        &:first-child {
          ${down('sm')} {
            margin-top: 2rem;
          }
        }
      }
    `
  )}

  ${ifProp(
    { alignment: RIGHT_LAYOUT },
    css`
      flex-direction: column-reverse;

      ${up('md')} {
        flex-direction: row-reverse;
      }

      .col-md-6 {
        ${down('sm')} {
          padding: 0;
        }

        &:first-child {
          ${down('sm')} {
            margin-top: 2rem;
          }
        }
      }
    `
  )}

  .col-md-6 {
    border-radius: 0.1rem;

    > * {
      &:last-child {
        ${down('xs')} {
          margin-bottom: 0;
        }
      }
    }
  }
`;

const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  max-height: 30rem;

  img {
    border-radius: 0.1rem;
    max-height: inherit;
    max-width: 100%;
    object-fit: contain;
  }
`;

const propTypes = {
  data: featuredImageLeftRightShape.isRequired,
};

function FeaturedImageLeftRight({ data }) {
  return (
    <Container>
      {data.fields.map((field, index) => (
        <Row
          className='align-items-center'
          alignment={field.alignment}
          key={`panel-${field.title}-${index}`}
        >
          <Col md={6}>
            {field.title && <RichText render={field.title} />}
            {field.body && <RichText render={field.body} />}
            {field.link && (
              <PrismicLink
                link={field.link}
                internalAs={Button}
                internalProps={{ forwardedAs: Link }}
                externalAs={Button}
                externalProps={{ target: '_blank' }}
              >
                {first(field.linkText).text}
              </PrismicLink>
            )}
          </Col>
          <Col md={6}>
            <ImageContainer>
              {field.img && (
                <img src={field.img.url} alt={getImgAlt(field.img)} />
              )}
            </ImageContainer>
          </Col>
        </Row>
      ))}
    </Container>
  );
}

FeaturedImageLeftRight.propTypes = propTypes;

export default FeaturedImageLeftRight;
