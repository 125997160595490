import React from 'react';
import { Link } from 'gatsby';
import Rating from 'react-rating';
import first from 'lodash/first';
import get from 'lodash/get';
import moment from 'moment';
import { between, down, up } from 'styled-breakpoints';
import BaseCol from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components/macro';
import BaseContainer from 'react-bootstrap/Container';
import ReviewDescription from './ReviewDescription';
import RichText from 'components/RichText';
import starGrey from 'images/star-grey.png';
import starYellow from 'images/star-yellow.png';
import PrismicLink from '../../PrismicLink';
import reviewsShape from 'shapes/reviewsShape';
import { theme } from 'styled-tools';
import PropTypes from 'prop-types';

const Container = styled(BaseContainer)`
  margin-bottom: 1rem;
  margin-top: 1rem;

  ${up('md')} {
    margin-bottom: 4rem;
    margin-top: 4rem;
  }
`;

const Col = styled(BaseCol)`
  ${down('md')} {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0.5rem;
    }
  }

  ${down('xs')} {
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`;

const ReviewBox = styled.div`
  background-color: ${theme('colors.white')};
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  height: 100%;
  padding: 1rem;
  width: 100%;

  ${down('sm')} {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  ${up('sm')} {
    padding: 2rem;
  }
`;

const StyledRating = styled(Rating)`
  margin-bottom: 1.375rem;

  & > span {
    margin-right: 0.5rem;
  }
`;

const ReviewDate = styled.div`
  margin-top: 1rem;
  color: ${theme('colors.muted')};
`;

const ReviewPersonName = styled.div`
  p {
    margin-bottom: 0;
  }
`;

const propTypes = {
  data: reviewsShape.isRequired,
  className: PropTypes.string,
};

function Reviews({ data, className }) {
  const reviews = data.fields;

  return (
    <Container className={className}>
      <Row>
        {reviews &&
          reviews.map((review, index) => (
            <>
              {review.status === 'Show' && review.reviewContent && (
                <Col md={4} key={`review-${index}`}>
                  <ReviewBox>
                    <StyledRating
                      start={0}
                      stop={5}
                      initialRating={parseInt(
                        get(review, 'reviewContent.rating', 0)
                      )}
                      readonly={true}
                      quiet={true}
                      emptySymbol={
                        <img
                          src={starGrey}
                          className='icon'
                          alt={'Grey star'}
                          style={{ width: '32px', height: '32px' }}
                        />
                      }
                      fullSymbol={
                        <img
                          src={starYellow}
                          className='icon'
                          alt={'Yellow star'}
                          style={{ width: '32px', height: '32px' }}
                        />
                      }
                    />
                    {review.reviewContent.title && (
                      <RichText render={review.reviewContent.title} />
                    )}
                    {review.reviewContent.description && (
                      <ReviewDescription
                        lines={3}
                        children={
                          <RichText render={review.reviewContent.description} />
                        }
                      />
                    )}
                    {review.reviewContent.personName && (
                      <ReviewPersonName>
                        &mdash; {review.reviewContent.personName}
                      </ReviewPersonName>
                    )}
                    {review.reviewContent.reviewDate && (
                      <ReviewDate>
                        {moment(review.reviewContent.reviewDate).format(
                          'MMM DD, YYYY'
                        )}
                      </ReviewDate>
                    )}
                    {review.reviewContent.source &&
                      review.reviewContent.sourceLink && (
                        <PrismicLink
                          link={review.reviewContent.sourceLink}
                          internalAs={Link}
                          internalProps={{ as: Link }}
                          externalAs='a'
                          externalProps={{
                            target: '_blank',
                          }}
                        >
                          {first(review.reviewContent.source).text}
                        </PrismicLink>
                      )}
                  </ReviewBox>
                </Col>
              )}
            </>
          ))}
      </Row>
    </Container>
  );
}

Reviews.propTypes = propTypes;

export default Reviews;
