import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/RichText';
import prismicRichTextShape from 'shapes/prismicRichTextShape';

const propTypes = {
  column: PropTypes.oneOfType([prismicRichTextShape, PropTypes.node]),
  head: PropTypes.object,
  headerKeys: PropTypes.array,
};

const defaultProps = {
  column: {},
  head: {},
  headerKeys: [],
};

function HeaderContent({ column }) {
  return (
    <>{Array.isArray(column) ? <RichText render={column} /> : <>{column}</>}</>
  );
}

HeaderContent.propTypes = propTypes;
HeaderContent.defaultProps = defaultProps;

export default HeaderContent;
