import PropTypes from 'prop-types';
import prismicLinkShape from 'shapes/prismicLinkShape';
import prismicImageShape from 'shapes/prismicImageShape';
import prismicRichTextShape from 'shapes/prismicRichTextShape';

const { shape, string, arrayOf, bool } = PropTypes;

export default shape({
  type: string.isRequired,
  label: string,
  primary: shape({
    body: prismicRichTextShape,
    title: prismicRichTextShape,
    bgColor: string,
    bgImg: prismicImageShape,
    bgImgGradient: bool,
    mobileGradient: bool,
  }),
  fields: arrayOf(
    shape({
      link: prismicLinkShape,
      text: string,
      type: string,
    })
  ),
});
