import React from 'react';
import omit from 'lodash/omit';
import first from 'lodash/first';
import TableTitle from './TableTitle';
import BoxTableTitle from './BoxTableTitle';
import ResponsiveTable from './ResponsiveTable';
import tableBlockShape from 'shapes/tableBlockShape';
import Container from 'react-bootstrap/Container';
import TableDisclaimer from './TableDisclaimer';
import PropTypes from 'prop-types';

const propTypes = {
  data: tableBlockShape.isRequired,
  className: PropTypes.string,
};

function TableBlock({ data, className }) {
  const { primary, fields } = data;

  const tableTitle = primary.table_title && first(primary.table_title);
  const tableDescription = primary.table_description && first(primary.table_description);
  const tableDisclaimer = primary.disclaimer;
  const tableBorder = primary.table_border;

  const title = tableTitle ? tableTitle.text : '';
  const description = tableDescription ? tableDescription.text : '';
  const label = tableTitle ? tableTitle.spans : '';
  const labelData = label && label.length !== 0 ? label[0].data : '';
  const isBoxHeader = labelData && labelData.label === 'Box';
  const filteredHead = omit(primary,
    ['table_title', 'table_description', 'disclaimer', 'table_border']
  );

  return (
    <Container className={className}>
      {title && isBoxHeader && (
        <BoxTableTitle banner={description} title={title} />
      )}
      {((!isBoxHeader && title) || (!isBoxHeader && description)) && (
        <TableTitle
          description={description}
          isBoxHeader={isBoxHeader}
          title={title}
        />
      )}
      <ResponsiveTable
        borderColor={tableBorder}
        columns={filteredHead}
        head={primary}
        rows={fields}
      />
      {tableDisclaimer && <TableDisclaimer disclaimer={tableDisclaimer} />}
    </Container>
  );
}

TableBlock.propTypes = propTypes;

export default TableBlock;
