import React from 'react';
import get from 'lodash/get';
import { ifProp, prop, theme } from 'styled-tools';
import { between, down, up } from 'styled-breakpoints';
import styled, { css } from 'styled-components/macro';
import Container from 'react-bootstrap/Container';
import RichText from 'components/RichText';
import featuredOnShape from 'shapes/textBlockShape';
import { getImgAlt } from 'utils/functions';
import PropTypes from 'prop-types';

const FeaturedContainer = styled.div`
  background-color: ${theme('colors.black')};
  color: ${theme('colors.white')};
  margin: 2rem 0;
  text-align: center;
  padding: 2rem 0;
`;

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;

  ${between('lg', 'xl')} {
    margin-left: auto;
    margin-right: auto;
    max-width: 65%;
  }
`;

const Logo = styled.div.attrs((props) => ({
  as: (props.to && Link) || (props.href && 'a'),
}))`
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
  max-height: 4rem;
  max-width: 13.25rem;
  min-height: 2.5rem;
  padding: 0 1rem;

  ${down('md')} {
    justify-content: center;
    max-height: none;
    min-height: auto;
    max-width: none;
    width: 33%;
  }

  img {
    /* filter is setting images to white with some grayscale for super bright/neon colors; will
    work for all browsers EXCEPT for IE11 -- logos will be color versions there */
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
      brightness(150%) contrast(125%);
    height: 100%;
    max-height: inherit;
    max-width: 100%;
    width: auto;
  }
`;

const propTypes = {
  data: featuredOnShape.isRequired,
  className: PropTypes.string,
};

function FeaturedOn({ data, className }) {
  const fields = get(data, 'fields', []);

  return (
    <FeaturedContainer className={className}>
      <Container>
        {get(data, 'primary.title') && (
          <RichText render={get(data, 'primary.title')} />
        )}
        <LogoContainer>
          {fields.map((field, index) => (
            <Logo href={get(field, 'logoLink.url')} key={`logo-${index}`}>
              <img alt={getImgAlt(field.logo)} src={get(field, 'logo.url')} />
            </Logo>
          ))}
        </LogoContainer>
        <div>
          {fields.map((field, index) => (
            <RichText key={`body-${index}`} render={get(field, 'body')} />
          ))}
        </div>
      </Container>
    </FeaturedContainer>
  );
}

FeaturedOn.propTypes = propTypes;

export default FeaturedOn;
