import PropTypes from 'prop-types';
import prismicImageShape from 'shapes/prismicImageShape';
import prismicRichTextShape from 'shapes/prismicRichTextShape';

const { shape, string, arrayOf } = PropTypes;

export default shape({
  type: string.isRequired,
  label: string,
  primary: shape({
    teamBioTitle: prismicRichTextShape,
  }),
  fields: arrayOf(
    shape({
      leadershipTeam: shape({
        title: prismicRichTextShape,
        name: prismicRichTextShape,
        bioBlurb: prismicRichTextShape,
        image: prismicImageShape,
      }),
    })
  ),
});
