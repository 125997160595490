import PropTypes from 'prop-types';
import prismicRichTextShape from 'shapes/prismicRichTextShape';
import prismicLinkShape from './prismicLinkShape';
import mailchimpSignupFormShape from 'shapes/mailchimpSignupFormShape';

const { shape, string, bool } = PropTypes;

export default shape({
  type: string.isRequired,
  label: string,
  primary: shape({
    cta_link_text: string,
    cta_header: prismicRichTextShape,
    cta_copy: prismicRichTextShape,
    cta_link: prismicLinkShape,
    app_buttons: bool,
    media_button: bool,
    email_capture_field: prismicRichTextShape,
    email_capture_button_text: prismicRichTextShape,
    signup_form: mailchimpSignupFormShape,
  }),
});
