import React from 'react';
import get from 'lodash/get';
import { ifProp, prop } from 'styled-tools';
import { down, up } from 'styled-breakpoints';
import RichText from 'components/RichText';
import styled, { css } from 'styled-components/macro';
import { Col as BaseCol, Row as BaseRow } from 'react-bootstrap';
import multiBlockShape from 'shapes/multiBlockShape';
import BaseContainer from 'react-bootstrap/Container';
import PropTypes from 'prop-types';

const propTypes = {
  data: multiBlockShape.isRequired,
  className: PropTypes.string,
};

const MultiBlockHeader = styled.div`
  margin-top: 1rem;
  text-align: center;

  .asterisks {
    color: ${prop('theme.colors.primary')};
    font-size: 1.5rem;
    position: relative;
    margin-left: -0.633rem;
    top: -1rem;

    ${down('sm')} {
      font-size: 0.8em;
      margin-left: -0.422em;
    }
  }

  h2 {
    ${down('sm')} {
      font-size: 4rem;
    }
  }
`;

const MultiBlockCopy = styled.div`
  font-size: 0.875rem;
  margin-top: 1rem;
  text-align: center;

  ${down('sm')} {
    font-size: 1.1rem;
  }
`;

const MultiBlockContainer = styled.div`
  border: 0.0625rem solid transparent;
  height: 100%;
  padding: 1rem;

  ${down('sm')} {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  ${ifProp('borderColor', css`
    border: 0.0625rem solid ${prop('borderColor')};
  `)}

  .block-img {
    margin: 0 auto;
    max-width: 90%;
  }
`;

const MultiBlockTitle = styled.div`
  margin-bottom: 2rem;
`;

const Row = styled(BaseRow)`
  justify-content: center;

  ${down('lg')} {
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
  }

  ${down('sm')} {
    max-width: none;
  }
`;

const Col = styled(BaseCol)`
  margin-bottom: 2rem;

  ${down('lg')} {
    flex-grow: unset;
    min-height: 13.75rem;
    min-width: 15rem;
  }

  ${down('xs')} {
    flex-basis: auto;
    padding: 0;
    width: 100%;
  }
`;

const Container = styled(BaseContainer)`
  margin-bottom: 1rem;
  margin-top: 1rem;

  ${up('md')} {
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
`;

function MultiBlock({ data, className }) {
  return (
    <Container className={className}>
      {get(data, 'primary.multi_block_title') && (
        <MultiBlockTitle>
          <RichText render={get(data, 'primary.multi_block_title')} />
        </MultiBlockTitle>
      )}
      <Row>
        {data.fields.map((field, index) => (
          <Col key={`multi_block-${index}`}>
            <MultiBlockContainer borderColor={field.multi_block_border_color}>
              {field.multi_block_header && (
                <MultiBlockHeader>
                  <RichText render={field.multi_block_header} />
                </MultiBlockHeader>
              )}
              {field.multi_block_copy && (
                <MultiBlockCopy>
                  <RichText render={field.multi_block_copy} />
                </MultiBlockCopy>
              )}
            </MultiBlockContainer>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

MultiBlock.propTypes = propTypes;

export default MultiBlock;
