import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import textBlockShape from 'shapes/textBlockShape';
import RichText from 'components/RichText';
import Container from 'react-bootstrap/Container';
import { graphql, Link, StaticQuery } from 'gatsby';
import {
  getCursorFromDocumentIndex,
  withPreview,
} from 'gatsby-source-prismic-graphql';
import BaseRow from 'react-bootstrap/Row';
import BaseCol from 'react-bootstrap/Col';
import { H3 } from 'components/Typography';
import styled from 'styled-components/macro';
import linkResolver from 'utils/linkResolver';
import get from 'lodash/get';
import Button from 'components/Button';
import { down, up } from 'styled-breakpoints';

const Row = styled(BaseRow)`
  ${down('md')} {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
`;

const Col = styled(BaseCol)`
  margin-bottom: 3rem;
  margin-top: 3rem;

  ${up('md')} {
    margin-bottom: 3rem;
  }

  &:nth-child(odd) {
    ${up('md')} {
      padding-right: 3rem;
    }
  }

  &:nth-child(even) {
    ${up('md')} {
      padding-left: 3rem;
    }
  }

  &:last-child {
    ${down('md')} {
      margin-bottom: 0;
    }
  }
`;

const blogPostsQuery = graphql`
  query BlogPostList(
    $first: Int = 20
    $last: Int
    $after: String
    $before: String
  ) {
    prismic {
      allBlogPosts: allBlog_posts(
        first: $first
        last: $last
        after: $after
        before: $before
        sortBy: post_date_DESC
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            _meta {
              id
              uid
              type
              lang
            }
            pageTitle: page_title
            postDate: post_date
            excerpt
          }
        }
      }
    }
  }
`;

const propTypes = {
  results: PropTypes.object,
  prismic: PropTypes.object,
  className: PropTypes.string,
};

function BlogListing({ results, prismic, className }) {
  const limit = 20;
  const didMountRef = useRef(false);
  const [page, setPage] = useState(-1);
  const [data, setData] = useState(results.prismic);

  useEffect(() => {
    if (
      !didMountRef.current ||
      !get(data, 'allBlogPosts.pageInfo.hasNextPage', false)
    ) {
      didMountRef.current = true;
      return;
    }

    prismic
      .load({
        query: blogPostsQuery,
        variables: { after: getCursorFromDocumentIndex(page) },
      })
      .then((res) => {
        if (page === -1) {
          // If the page is reset due to hot-reload, override the posts instead of concatenating them.
          setData(res.data);
        } else {
          setData({
            ...res.data,
            allBlogPosts: {
              ...res.data.allBlogPosts,
              edges: get(data, 'allBlogPosts.edges', []).concat(
                get(res, 'data.allBlogPosts.edges', [])
              ),
            },
          });
        }
      });
  }, [page]);

  if (!data) {
    return null;
  }

  const onNextClick = () => setPage(page + limit);

  return (
    <Container className={className}>
      <Row xs={1} md={2}>
        {get(data, 'allBlogPosts.edges', []).map((edge, index) => {
          const node = edge.node;
          const postTitle = get(node, 'pageTitle[0].text', '');

          return (
            <Col key={`post-${index}`}>
              {node.postDate}
              <H3>
                <Link to={linkResolver(node._meta)}>{postTitle}</Link>
              </H3>
              <RichText render={node.excerpt} />
              <Link to={linkResolver(node._meta)}>Read more</Link>
            </Col>
          );
        })}
      </Row>
      {get(data, 'allBlogPosts.pageInfo.hasNextPage', false) && (
        <Button variant='primary' onClick={onNextClick}>
          View more
        </Button>
      )}
    </Container>
  );
}

BlogListing.propTypes = propTypes;

export default (props) => (
  <StaticQuery
    query={blogPostsQuery}
    render={withPreview(
      (data) => (
        <BlogListing results={data} {...props} />
      ),
      blogPostsQuery
    )}
  />
);
