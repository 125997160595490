import PropTypes from 'prop-types';
import prismicLinkShape from './prismicLinkShape';
import prismicImageShape from 'shapes/prismicImageShape';
import prismicRichTextShape from 'shapes/prismicRichTextShape';

const { shape, string, arrayOf } = PropTypes;

export default shape({
  type: string.isRequired,
  label: string,
  primary: shape({
    carouseTitle: prismicRichTextShape,
    commentaryBody: prismicRichTextShape,
    commentaryHeader: prismicRichTextShape,
  }),
  fields: arrayOf(
    shape({
      tite: prismicRichTextShape,
      body: prismicRichTextShape,
      image: prismicImageShape,
      ctaLink: prismicLinkShape,
      ctaStyle: string,
      ctaText: string,
    })
  ),
});
