import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components/macro';

const StyledTd = styled.td`
  border-bottom: 1px solid ${prop('theme.colors.gray')};
  display: flex;
  empty-cells: show;
  padding: 0.625rem 0.875rem;
  text-align: right;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    display: table-cell;
    padding: 0.875rem 1rem;
    text-align: left;
    vertical-align: top;
  }

  ${ifProp('borderColor', css`
    border-bottom: 0.0625rem solid ${prop('borderColor')};
  `)}

  &:last-child {
    border-bottom: 0.0625 solid ${prop('theme.colors.gray')};

    ${ifProp('borderColor', css`
      border-bottom: 0.0625rem solid ${prop('borderColor')};
    `)}
  }

  > * {
    font-size: 0.8125rem;
    line-height: inherit;
    width: 50%;

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      font-size: 0.875rem;
      width: auto;
    }
  }

  h4 {
    font-size: 0.8125rem;
    line-height: inherit;
    margin: 0;

    @media (max-width: ${prop('theme.breakpoints.md')}) {
      font-weight: ${prop('theme.fontWeights.default.regular')};
    }
  }
`;

export default StyledTd;
