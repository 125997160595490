import PropTypes from 'prop-types';

const { shape, arrayOf, string, number } = PropTypes;

export default arrayOf(
  shape({
    title: string,
    thumbnail_height: number,
    width: number,
    height: number,
    version: string,
    type: string,
    provider_url: string,
    thumbnail_url: string,
    author_name: string,
    author_url: string,
    provider_name: string,
    html: string,
    thumbnail_width: number,
    embed_url: string,
  })
);
