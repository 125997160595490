import React from 'react';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import AppDownload from 'components/AppDownload';
import AppDownloadContainer from 'components/AppDownload/AppDownloadContainer';
import prismicLinkShape from 'shapes/prismicLinkShape';
import prismicImageShape from 'shapes/prismicImageShape';

const propTypes = {
  appStoreLink: prismicLinkShape,
  appStoreImage: prismicImageShape,
  googlePlayLink: prismicLinkShape,
  googlePlayImage: prismicImageShape,
};

function AppDownloads({
  appStoreImage,
  appStoreLink,
  googlePlayImage,
  googlePlayLink,
}) {
  return (
    <>
      {isIOS && appStoreLink && appStoreImage && (
        <AppDownload appLogo={appStoreImage} downloadLink={appStoreLink} />
      )}
      {isAndroid && googlePlayLink && googlePlayImage && (
        <AppDownload appLogo={googlePlayImage} downloadLink={googlePlayLink} />
      )}
      {!isMobile && (
        <AppDownloadContainer>
          {googlePlayLink && googlePlayImage && (
            <AppDownload
              appLogo={googlePlayImage}
              downloadLink={googlePlayLink}
            />
          )}
          {appStoreLink && appStoreImage && (
            <AppDownload appLogo={appStoreImage} downloadLink={appStoreLink} />
          )}
        </AppDownloadContainer>
      )}
    </>
  );
}

AppDownloads.propTypes = propTypes;

export default AppDownloads;
