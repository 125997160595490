import PropTypes from 'prop-types';
import prismicRichTextShape from 'shapes/prismicRichTextShape';

const { shape, string, arrayOf } = PropTypes;

export default shape({
  type: string.isRequired,
  label: string,
  primary: shape({
    header: prismicRichTextShape,
  }),
  fields: arrayOf(
    shape({
      content: prismicRichTextShape,
    })
  ),
});
