import React from 'react';
import PropTypes from 'prop-types';
import { up } from 'styled-breakpoints';
import { ifProp, prop, withProp } from 'styled-tools';
import blockStyleShape from 'shapes/blockStyleShape';
import styled, { css } from 'styled-components/macro';
import pick from 'lodash/pick';

const DEFAULT_PADDING_Y = 16;
const DEFAULT_MARGIN_Y = 0;

const BlockStyleContainer = styled.div`
  width: 100%;
  
  padding: ${withProp(
    ['paddingTop', 'paddingBottom'],
    (paddingTop, paddingBottom) =>
      `${paddingTop || DEFAULT_PADDING_Y}px 0 ${
        paddingBottom || DEFAULT_PADDING_Y
      }px`
  )};
  
  margin: ${withProp(
    ['marginTop', 'marginBottom'],
    (marginTop, marginBottom) =>
      `${marginTop || DEFAULT_MARGIN_Y}px 0 ${
        marginBottom || DEFAULT_MARGIN_Y
      }px`
  )};
  
  ${ifProp(
    'textColor',
    css`
      color: ${prop('textColor')};
    `
  )}
  
  ${ifProp(
    'bgColor',
    css`
      background-color: ${prop('bgColor')};
    `
  )}

  ${ifProp(
    'bgImage',
    css`
      background-image: url("${prop('bgImage.url')}");
      background-size: cover;
      background-repeat: no-repeat;
      background-position-x: center;
      min-height: 30rem;

      ${up('xs')} {
        background-position-x: 40%;
      }

      ${up('md')} {
        background-position-x: right;
      }
    `
  )}
`;

const BLOCK_STYLE_FIELDS = [
  'bgColor',
  'bgImage',
  'textColor',
  'marginTop',
  'marginBottom',
  'paddingTop',
  'paddingBottom',
];

const propTypes = {
  data: blockStyleShape.isRequired,
  children: PropTypes.node,
};

function BlockStyle({ data, children }) {
  if (!data || !data.primary) {
    return children;
  }

  const blockStyles = pick(data.primary, BLOCK_STYLE_FIELDS);

  return <BlockStyleContainer {...blockStyles}>{children}</BlockStyleContainer>;
}

BlockStyle.propTypes = propTypes;

export default BlockStyle;
