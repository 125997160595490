import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import mailchimpSignupFormShape from 'shapes/mailchimpSignupFormShape';
import parseQuery from 'utils/parseQuery';

const MailchimpWrapper = styled.div`
  margin: 1rem 0;

  input {
    font-size: 0.875rem;
  }

  // Needed for specificity override of MC CSS.
  &#mc_embed_signup {
    input.mce_inline_error {
      border-color: ${theme('colors.darkRed')};
    }

    div.mce_inline_error {
      margin: 0;
      background-color: ${theme('colors.darkRed')};
    }
  }
`;

const EmailFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const EmailFieldGroup = styled.div`
  display: block;
  max-width: 20rem;
  min-height: 3.125rem;
  width: 14rem;

  input {
    display: block;
    width: 100%;
    padding: 0.8rem 0.5rem;
    text-indent: 2%;
    border: 1px solid ${theme('colors.lightGray')};
    border-right: none;
    border-radius: 0.25rem 0 0 0.25rem;
  }
`;

const SubmitButton = styled.input`
  font-size: 0.875rem;
  font-weight: ${theme('fontWeights.default.semiBold')};
  height: 3.036rem;
  border-radius: 0 0.25rem 0.25rem 0;
`;

const Response = styled.div`
  margin-top: -1px;
  padding: 0.3125rem 0.625rem;
  width: 14rem;
  color: ${theme('colors.white')};
  font-weight: ${theme('fontWeights.default.bold')};
`;

const ErrorResponse = styled(Response)`
  background-color: ${theme('colors.darkRed')};
`;

const SuccessResponse = styled(Response)`
  background-color: ${theme('colors.success')};
`;

const propTypes = {
  emailCaptureText: PropTypes.string.isRequired,
  emailButtonText: PropTypes.string.isRequired,
  signupForm: mailchimpSignupFormShape.isRequired,
};

function initializeMailchimpScripts() {
  const existingScript = document.getElementById('mailchimpValidate');

  if (!existingScript) {
    // Add the mailchimp mc-validate.js script to the document.
    const script = document.createElement('script');
    script.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
    script.id = 'mailchimpValidate';
    script.onload = function () {
      (function ($) {
        window.fnames = [];
        window.ftypes = [];
        window.fnames[0] = 'EMAIL';
        window.ftypes[0] = 'email';
      })(window.jQuery);
      window.$mcj = window.jQuery.noConflict(true);
    };
    document.body.appendChild(script);
  }

  // Remove the MC script when component unmounts. This is required, so that
  // when navigating to a different page with an MC form the scripts are
  // re-added and re-run in order to bind to that form properly.
  return () => {
    const scriptToRemove = document.getElementById('mailchimpValidate');

    if (scriptToRemove) {
      document.body.removeChild(scriptToRemove);
    }
  };
}

function MailchimpForm({ emailCaptureText, emailButtonText, signupForm }) {
  const {
    formUrl,
    groupName,
    groupValue = '1', // default field value to '1' which would be the first option in the field group in MC.
  } = signupForm;

  useEffect(initializeMailchimpScripts, []);

  // If we don't have a form URL, the form will not function, so we return null if that's the case.
  if (!formUrl) {
    return null;
  }

  // Parse the query parameters from the MC form url, if available.
  // These are used for the bot protection input name since it needs to match
  // the form's params.
  const formUrlQueryString = formUrl.split('?')[1];
  const formUrlQueryParams = formUrlQueryString
    ? parseQuery(formUrlQueryString)
    : {};

  return (
    <MailchimpWrapper id='mc_embed_signup'>
      <form
        action={formUrl}
        method='post'
        id='mc-embedded-subscribe-form'
        name='mc-embedded-subscribe-form'
        className='validate'
        target='_blank'
        noValidate
      >
        {groupName && (
          // Hidden input field for the group field to save this contact in.
          <input type='hidden' name={groupName} value={groupValue} />
        )}

        {formUrlQueryParams && formUrlQueryParams.u && formUrlQueryParams.id && (
          // real people should not fill this in and expect good things - do not remove this or risk form bot signups
          <div
            style={{ position: 'absolute', left: '-5000px' }}
            aria-hidden='true'
          >
            <input
              type='text'
              name={`b_${formUrlQueryParams.u}_${formUrlQueryParams.id}`}
              tabIndex='-1'
              value=''
            />
          </div>
        )}

        <EmailFieldContainer>
          <EmailFieldGroup className='mc-field-group'>
            <input
              type='email'
              name='EMAIL'
              className='email'
              placeholder={emailCaptureText}
              id='mce-EMAIL'
            />
          </EmailFieldGroup>
          <SubmitButton
            type='submit'
            value={emailButtonText}
            name='subscribe'
            id='mc-embedded-subscribe'
            className='btn btn-primary btn-submit'
          />
        </EmailFieldContainer>
        <div id='mce-responses'>
          <ErrorResponse
            className='response'
            id='mce-error-response'
            style={{ display: 'none' }}
          />
          <SuccessResponse
            className='response'
            id='mce-success-response'
            style={{ display: 'none' }}
          />
        </div>
      </form>
    </MailchimpWrapper>
  );
}

MailchimpForm.propTypes = propTypes;

export default MailchimpForm;
