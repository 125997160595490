import PropTypes from 'prop-types';
import prismicLinkShape from './prismicLinkShape';
import prismicImageShape from 'shapes/prismicImageShape';
import prismicRichTextShape from 'shapes/prismicRichTextShape';
import prismicEmbedVideoLinkShape from './prismicEmbedVideoLinkShape';
import mailchimpSignupFormShape from 'shapes/mailchimpSignupFormShape';

const { bool, shape, string } = PropTypes;

export default shape({
  type: string.isRequired,
  label: string,
  primary: shape({
    image: prismicImageShape,
    header: prismicRichTextShape,
    disclaimer: prismicRichTextShape,
    description: prismicRichTextShape,
    video: prismicEmbedVideoLinkShape,
    textAlignment: string,
    ctaType: string,
    buttonText: string,
    buttonLink: prismicLinkShape,
    appButtons: bool,
    mediaButton: bool,
    emailCaptureField: prismicRichTextShape,
    emailCaptureButtonText: prismicRichTextShape,
    signupForm: mailchimpSignupFormShape,
    imageGradient: bool,
    mobileGradient: bool,
  }),
});
