import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components/macro';

const StyledTable = styled.table`
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  padding: 0;
  width: 100%;

  tr {
    border-bottom: 0.0625rem solid ${prop('theme.colors.greyLightest')};
    border-left: 0.0625rem solid ${prop('theme.colors.greyLightest')};
    border-right: 0.0625rem solid ${prop('theme.colors.greyLightest')};
    display: block;
    margin-bottom: 0;

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      display: table-row;
      border: none;
    }

    ${ifProp('borderColor', css`
      border-bottom: 0.0625rem solid ${prop('borderColor')};
      border-left: 0.0625rem solid ${prop('borderColor')};
      border-right: 0.0625rem solid ${prop('borderColor')};
    `)}
  }

  tbody {
    tr:first-child {
      border-top: 0.15rem solid ${prop('theme.colors.greyLightest')};

      ${ifProp('borderColor', css`
        border-top: 0.15rem solid ${prop('borderColor')};
      `)}
    }
  }
`;

export default StyledTable;
