import React, { useState } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import RichText from 'components/RichText';
import { down } from 'styled-breakpoints';
import styled, { css } from 'styled-components/macro';
import { prop, theme, switchProp } from 'styled-tools';
import prismicImageShape from 'shapes/prismicImageShape';
import prismicRichTextShape from 'shapes/prismicRichTextShape';
import { getImgAlt } from 'utils/functions';

const PanelIcon = styled.img`
  height: 6rem;
  margin-bottom: 2rem;
  max-width: 6rem;

  ${switchProp('size', {
    ['Medium']: css`
      width: 50%;
      max-width: 5.125rem;
    `,
    ['Large']: css`
      width: 100%;
      height: 100%;
      max-width: 7.125rem;
      margin-bottom: 1rem;
    `,
  })}
`;

const PanelIconWrapper = styled.div`
  display: flex;

  ${switchProp('alignment', {
    ['Left']: css`
      justify-content: flex-start;
    `,
    ['Centered']: css`
      justify-content: center;
    `,
    ['Right']: css`
      justify-content: flex-end;
    `,
  })}

  ${switchProp('size', {
    ['Large']: css`
      justify-content: center;
    `,
  })}
`;

const PanelContainer = styled.div`
  box-shadow: 0rem 0.1875rem 0.375rem rgba(0, 0, 0, 0.2);
  cursor: pointer;
  height: 18.75rem;
  padding: 1rem 1rem 0.5rem;
  background-color: ${theme('colors.white')};
  margin-bottom: 2rem;
  border-radius: 1rem;

  ${down('lg')} {
    padding: 2rem;
  }

  &:hover {
    background-color: ${theme('colors.black')};

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${theme('colors.white')};
    }
  }
`;

const PanelHeader = styled.div`
  text-align: left;

  p {
    margin-bottom: 0;
  }
`;

const PanelBody = styled.div`
  font-size: 1rem;

  // Adds ellipses if more than four lines of text which
  // would otherwise overflow the panel
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  > * {
    margin-bottom: 0;
  }
`;

const propTypes = {
  field: PropTypes.shape({
    differentiatorIcon: prismicImageShape,
    differentiatorHoverIcon: prismicImageShape,
    differentiatorHeader: prismicRichTextShape,
    differentiatorBody: prismicRichTextShape,
  }),
};

function Panel({ field }) {
  const [image, setImage] = useState('');
  const icon = get(field, 'icon.url');
  const iconHover = get(field, 'hoverIcon.url');

  return (
    <PanelContainer
      backgroundHoverImg={iconHover}
      onMouseEnter={() => {
        setImage(iconHover);
      }}
      onMouseLeave={() => {
        setImage(icon);
      }}
    >
      {field.icon && (
        <PanelIconWrapper
          alignment={field && field.iconAlignment}
          size={field && field.iconSize}
        >
          <PanelIcon
            alt={getImgAlt(image)}
            src={image ? image : icon}
            size={field && field.iconSize}
          />
        </PanelIconWrapper>
      )}
      {field.header && (
        <PanelHeader>
          <RichText render={field.header} />
        </PanelHeader>
      )}
      {field.body && (
        <PanelBody>
          <RichText render={field.body} />
        </PanelBody>
      )}
    </PanelContainer>
  );
}

Panel.propTypes = propTypes;

export default Panel;
