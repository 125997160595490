import styled from 'styled-components/macro';
import { down, up } from 'styled-breakpoints';

const MediaDownloadContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${up('xl')} {
    flex-direction: row;
  }
`;

export default MediaDownloadContainer;
