import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { down, up } from 'styled-breakpoints';
import { ifProp, prop, theme } from 'styled-tools';
import first from 'lodash/first';
import get from 'lodash/get';
import ctaBannerShape from 'shapes/ctaBannerShape';
import styled, { css } from 'styled-components/macro';
import Col from 'react-bootstrap/Col';
import Button from 'components/Button';
import BaseRow from 'react-bootstrap/Row';
import RichText from 'components/RichText';
import AppDownloads from './AppDownloads';
import MediaDownloads from './MediaDownloads';
import CtaBannerButtons from './CtaBannerButtons';
import Container from 'react-bootstrap/Container';
import PrismicLink from 'components/PrismicLink';
import MailchimpForm from 'components/MailchimpForm';
import { getImgAlt, isEmptyOrNull } from 'utils/functions';
import { LEFT_LAYOUT, RIGHT_LAYOUT } from 'utils/constants';
import { withPreview } from 'gatsby-source-prismic-graphql';
import AppDownloadContainer from 'components/AppDownload/AppDownloadContainer';

const CTABannerContainer = styled(Container)`
  color: ${theme('colors.white')};
  margin-bottom: 2rem;
  margin-top: 2rem;
  min-height: 24rem;
  padding: 1rem 0;
  text-align: left;

  ${up('md')} {
    margin-bottom: 3rem;
    margin-top: 3rem;
  }

  strong {
    color: ${theme('colors.orange')};
  }

  ${ifProp(
    'image',
    css`
      background-image: url("${prop('mobileImage')}");
      background-size: cover;
      background-repeat: no-repeat;
      background-position-x: center;
      min-height: 30rem;

      ${up('xs')} {
        background-position-x: 40%;
      }

      ${up('sm')} {
        background-image: url("${prop('image')}");
      }

      ${up('md')} {
        background-position-x: right;
      }
    `
  )}

  ${ifProp(
    ['mobileImage', { mobileGradient: true }],
    css`
    ${down('sm')} {
      background-image: linear-gradient(183deg, rgba(0,0,0,0.38) 0%, #000000 92%), url("${prop(
        'mobileImage'
      )}");
    }
    `
  )}

  ${ifProp(
    ['image', { imageGradient: true }],
    css`
      ${up('sm')} {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.05)), url("${prop(
          'image'
        )}");
      }
    `
  )}
`;

const Row = styled(BaseRow)`
  margin: 0;

  ${ifProp(
    { alignment: LEFT_LAYOUT },
    css`
      flex-direction: column;

      ${up('md')} {
        flex-direction: row;
      }

      .col-md-6 {
        &:first-child {
          ${down('sm')} {
            margin-bottom: 3.5rem;
          }
        }
      }
    `
  )}

  ${ifProp(
    { alignment: RIGHT_LAYOUT },
    css`
      flex-direction: column-reverse;

      ${up('md')} {
        flex-direction: row-reverse;
      }

      .col-md-6 {
        &:last-child {
          ${down('sm')} {
            margin-bottom: 3.5rem;
          }
        }
      }
    `
  )}
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 24rem;

  ${down('sm')} {
    justify-content: flex-end;
    min-height: auto;
  }

  .video-container {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
`;

const ContentContainer = styled.div`
  .btn-primary {
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }

  ${AppDownloadContainer} {
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }
`;

const Disclaimer = styled.div`
  margin: 1rem 0;
`;

const appDownloadLinksQuery = graphql`
  query CtaBannerAppDownloadLinksQuery {
    prismic {
      allSocial_medias {
        edges {
          node {
            ...AppDownloadLinksFragment
            ...MediaDownloadLinksFragment
          }
        }
      }
    }
  }
`;

const propTypes = {
  data: ctaBannerShape.isRequired,
  appDownloadLinksData: PropTypes.any,
  className: PropTypes.string,
};

function CtaBanner({ data, appDownloadLinksData, className }) {
  if (!data && !data.primary) {
    return null;
  }

  const {
    image,
    imageGradient,
    mobileGradient,
    header,
    disclaimer,
    description,
    video,
    textAlignment,
    ctaType,
    buttonText,
    buttonLink,
    appButtons,
    mediaButtons,
    emailCaptureField,
    emailCaptureButtonText,
    signupForm,
  } = data.primary;

  const appDownloadLinks = get(
    appDownloadLinksData,
    'prismic.allSocial_medias.edges[0].node',
    {}
  );

  const {
    appStoreImage,
    appStoreLink,
    googlePlayLink,
    googlePlayImage,
    youtubeLink,
    spotifyLink,
    applePodcastLink,
    youtubeImage,
    spotifyImage,
    applePodcastImage,
  } = appDownloadLinks;

  const buttonComponent = ctaType && ctaType === 'Button' ? Button : Link;

  const mainImageUrl = get(image, 'url');
  const mobileImageUrl = get(image, 'mobile.url', mainImageUrl);

  return (
    <CTABannerContainer
      className={className}
      alt={image && getImgAlt(image)}
      image={mainImageUrl}
      mobileImage={mobileImageUrl}
      imageGradient={imageGradient}
      mobileGradient={mobileGradient}
      fluid
    >
      <Container>
        <Row alignment={textAlignment || LEFT_LAYOUT} noGutters>
          <StyledCol md={6}>
            <ContentContainer>
              {header && <RichText render={header} />}
              {description && <RichText render={description} />}
              {signupForm && (
                <MailchimpForm
                  emailCaptureText={
                    !isEmptyOrNull(emailCaptureField)
                      ? first(emailCaptureField).text
                      : 'Your email address'
                  }
                  emailButtonText={
                    !isEmptyOrNull(emailCaptureButtonText)
                      ? first(emailCaptureButtonText).text
                      : 'Subscribe'
                  }
                  signupForm={signupForm}
                />
              )}
              <CtaBannerButtons
                buttonText={buttonText}
                buttonComponent={buttonComponent}
                buttonLink={buttonLink}
                appButtons={appButtons}
                mediaButtons={mediaButtons}
                appStoreLink={appStoreLink}
                appStoreImage={appStoreImage}
                googlePlayImage={googlePlayImage}
                googlePlayLink={googlePlayLink}
                spotifyImage={spotifyImage}
                spotifyLink={spotifyLink}
                youtubeImage={youtubeImage}
                youtubeLink={youtubeLink}
                applePodcastImage={applePodcastImage}
                applePodcastLink={applePodcastLink}
              />
              {disclaimer && (
                <Disclaimer>
                  <RichText render={disclaimer} />
                </Disclaimer>
              )}
            </ContentContainer>
          </StyledCol>
          {video && (
            <StyledCol md={6}>
              <div
                className='video-container'
                dangerouslySetInnerHTML={{ __html: video.html }}
              />
            </StyledCol>
          )}
        </Row>
      </Container>
    </CTABannerContainer>
  );
}

CtaBanner.propTypes = propTypes;

export default (props) => (
  <StaticQuery
    query={appDownloadLinksQuery}
    render={withPreview(
      (data) => (
        <CtaBanner appDownloadLinksData={data} {...props} />
      ),
      appDownloadLinksQuery
    )}
  />
);
