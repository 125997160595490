import React from 'react';
import get from 'lodash/get';
import { switchProp } from 'styled-tools';
import { down, up } from 'styled-breakpoints';
import styled, { css } from 'styled-components/macro';
import {
  Row,
  Col as BaseCol,
  Container as BaseContainer,
} from 'react-bootstrap';
import Panel from './Panel';
import Carousel from 'components/Carousel';
import RichText from 'components/RichText';
import differentiatorShape from 'shapes/differentiatorShape';
import PropTypes from 'prop-types';

const DifferentiatorTitle = styled.div`
  margin: 1rem 0;

  ${up('md')} {
    margin-bottom: 3rem;
    margin-top: 2rem;
  }

  > * {
    text-align: center;
  }
`;

const Col = styled(BaseCol)`
  ${down('xs')} {
    margin-bottom: 1rem;
  }
`;

const Container = styled(BaseContainer)`
  ${switchProp('type', {
    ['static']: css`
      margin-bottom: 1rem;
      margin-top: 1rem;

      ${up('md')} {
        margin-bottom: 2rem;
        margin-top: 2rem;
      }

      ${up('lg')} {
        margin-bottom: 4rem;
        margin-top: 4rem;
      }
    `,
    ['carousel']: css`
      margin-top: 2rem;
      margin-bottom: 3rem;

      ${up('lg')} {
        margin-top: 4rem;
        margin-bottom: 5rem;
        padding: 2.5rem;
      }
    `,
  })}

  ${down('md')} {
    max-width: 90%;
  }

  ${down('xs')} {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  ${up('xl')} {
    max-width: 90%;
  }
`;

const propTypes = {
  data: differentiatorShape.isRequired,
  className: PropTypes.string,
};

function Differentiator({ data, className }) {
  const fields = data.fields;

  return (
    <Container
      type={fields.length < 4 ? 'static' : 'carousel'}
      className={className}
    >
      {get(data, 'primary.title') && (
        <DifferentiatorTitle>
          <RichText render={get(data, 'primary.title')} />
        </DifferentiatorTitle>
      )}
      {fields.length < 4 ? (
        <Row md={5}>
          {fields.map((field, index) => (
            <Col>
              <Panel key={`panel-${index}`} field={field} />
            </Col>
          ))}
        </Row>
      ) : (
        <Carousel type='differentiator'>
          {fields.map((field, index) => (
            <Panel key={`panel-${index}`} field={field} />
          ))}
        </Carousel>
      )}
    </Container>
  );
}

Differentiator.propTypes = propTypes;

export default Differentiator;
