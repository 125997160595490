import React from 'react';
import get from 'lodash/get';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components/macro';
import { ifProp, prop, theme } from 'styled-tools';
import { down, up } from 'styled-breakpoints';
import Button from 'components/Button';
import RichText from 'components/RichText';
import Container from 'react-bootstrap/Container';
import PrismicLink from 'components/PrismicLink';
import { getImgAlt } from 'utils/functions';
import centeredFeatureShape from 'shapes/centeredFeatureShape';
import PropTypes from 'prop-types';

const StyledContainer = styled(Container)`
  margin: 0 auto;
  text-align: center;
  max-width: 90%;

  ${down('sm')} {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  &:last-child {
    margin-bottom: 1rem;
  }
`;

const ContentWrapper = styled.div`
  background-color: ${prop('bgColor')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const UpperContent = styled.div`
  padding: 1em 0;

  .centered-link {
    margin: 0 0.5rem;

    ${down('sm')} {
      margin: 0 0.5rem 0.5rem;
    }
  }

  .block-img {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
`;

const ImageContent = styled.figure`
  width: 100%;
  height: ${prop('height')}px;
  max-height: 40rem;
  margin: 0;
  border-radius: 0.1rem;
  box-shadow: rgba(25, 28, 31, 0.08) 0 14px 32px,
    rgba(25, 28, 31, 0.04) 0 8px 16px, rgba(25, 28, 31, 0.1) 0px -1px 0px;

  ${down('xs')} {
    max-height: 25rem;
  }

  ${ifProp(
    'img',
    css`
      background-image: url("${prop('mobileImg')}");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      ${up('sm')} {
        background-image: url("${prop('img')}");
      }
  `
  )}

  ${ifProp(
    ['mobileImg', { mobileGradient: true }],
    css`
      ${down('sm')} {
        background-image: linear-gradient(183deg, rgba(0,0,0,0.38) 0%, #000000 92%), url("${prop(
          'mobileImg'
        )}");
      }
    `
  )}

  ${ifProp(
    ['img', { bgImgGradient: true }],
    css`
      ${up('sm')} {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.05)), url("${prop(
          'img'
        )}");
      }
    `
  )}
`;

const propTypes = {
  data: centeredFeatureShape.isRequired,
  className: PropTypes.string,
};

function CenteredFeature({ data, className }) {
  const {
    title,
    body,
    bgColor,
    bgImg,
    bgImgGradient,
    mobileGradient,
  } = data.primary;

  const mainImageUrl = get(bgImg, 'url');
  const mobileImageUrl = get(bgImg, 'mobile.url', mainImageUrl);

  return (
    <StyledContainer className={className}>
      <ContentWrapper bgColor={bgColor}>
        <UpperContent>
          {title && <RichText render={title} />}
          {body && <RichText render={body} />}
          {data.fields.map((field, index) => (
            <>
              {field.link && (
                <PrismicLink
                  key={`centered-feature-${index}`}
                  link={get(field, 'link')}
                  internalAs={field.type === 'Link' ? Link : Button}
                  internalProps={{ forwardedAs: Link }}
                  externalAs={field.type === 'Link' ? 'a' : Button}
                  externalProps={{
                    target: '_blank',
                  }}
                  className={'centered-link'}
                >
                  {field.text}
                </PrismicLink>
              )}
            </>
          ))}
        </UpperContent>
        {bgImg && (
          <ImageContent
            alt={getImgAlt(bgImg)}
            img={mainImageUrl}
            mobileImg={mobileImageUrl}
            bgImgGradient={bgImgGradient}
            mobileGradient={mobileGradient}
            height={bgImg.dimensions.height}
          />
        )}
      </ContentWrapper>
    </StyledContainer>
  );
}

CenteredFeature.propTypes = propTypes;

export default CenteredFeature;
