import PropTypes from 'prop-types';
import prismicImageShape from 'shapes/prismicImageShape';

const { shape, string, number } = PropTypes;

export default shape({
  type: string.isRequired,
  label: string,
  primary: shape({
    bgColor: string,
    bgImage: prismicImageShape,
    textColor: string,
    marginTop: number,
    marginBottom: number,
    paddingTop: number,
    paddingBottom: number,
  }),
});
