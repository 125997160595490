import React from 'react';
import RichText from 'components/RichText';
import richTextShape from 'shapes/prismicRichTextShape';
import styled from 'styled-components/macro';

const propTypes = {
  disclaimer: richTextShape,
};

const StyledDisclaimer = styled.div`
  display: flex;
  margin: 1rem 0;
`;

function TableDisclaimer({ disclaimer }) {
  return (
    <StyledDisclaimer>
      <RichText render={disclaimer} />
    </StyledDisclaimer>
  );
}

TableDisclaimer.propTypes = propTypes;

export default TableDisclaimer;
