import styled from 'styled-components/macro';
import { down, up } from 'styled-breakpoints';

const AppDownloadContainer = styled.div`
  display: flex;

  ${down('xs')} {
    flex-direction: column;
  }

  > {
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      ${down('xs')} {
        margin-left: 0;
      }
    }
  }
`;

export default AppDownloadContainer;
