import { prop } from 'styled-tools';
import styled from 'styled-components/macro';

const StyledTh = styled.th`
  color: ${prop('theme.colors.white')};
  font-weight: ${prop('theme.fontWeights.default.regular')};
  padding: 0.625rem 0.875rem;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    padding: 0.875rem 1rem;
  }

  > * {
    color: ${prop('theme.colors.black')};
    font-weight: ${prop('theme.fontWeights.default.regular')};
  }

  h4 {
    font-size: 0.8125rem;
    font-weight: ${prop('theme.fontWeights.default.regular')};

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      font-size: 1rem;
    }
  }
`;

export default StyledTh;
