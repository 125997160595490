import React from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components/macro';
import RichText from 'components/RichText';
import textBlockShape from 'shapes/textBlockShape';
import { Container as BaseContainer } from 'react-bootstrap';
import PropTypes from 'prop-types';

const propTypes = {
  data: textBlockShape.isRequired,
  className: PropTypes.string,
};

const Container = styled(BaseContainer)`
  margin-bottom: 2rem;
  margin-top: 2rem;
  overflow-wrap: break-word;
  word-wrap: break-word;

  ${up('lg')} {
    margin-bottom: 4rem;
    margin-top: 4rem;
  }

  &.small-video {
    .embed-youtube {
      margin: 0 auto;
      width: 100%;

      ${up('md')} {
        width: 50%;
      }
    }
  }

  .block-img {
    margin-bottom: 1rem;
    width: fit-content;
    text-align: center;

    &.text-center {
      margin-left: auto;
      margin-right: auto;
    }

    &.p {
      text-align: center;
    }
  }

  .embed-youtube {
    div {
      height: 0;
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;

      iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
`;

function TextBlock({ data, className }) {
  const { label } = data;

  return (
    <Container className={className + `${label ? ' ' + label : ''}`}>
      {data.primary.header && <RichText render={data.primary.header} />}
      {data.fields.map((field, index) => (
        <RichText render={field.content} key={index} />
      ))}
    </Container>
  );
}

TextBlock.propTypes = propTypes;

export default TextBlock;
